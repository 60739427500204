const canvas = document.getElementById('canvas1');
const ctx = canvas.getContext('2d');
canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

let particleArray = [];
let adjustX = 1; //TODO: update for screen size
let adjustY = 4;

//handle mouse

// global fill style
let fill_r = 255;
let fill_g = 255;
let fill_b = 255;

let mouse = {
  x: null,
  y: null,
  radius: 100 // TODO: update for screen size
}

window.addEventListener('mousemove', function (event) {
  mouse.x = event.x;
  mouse.y = event.y;
});

ctx.fillStyle = 'white';
ctx.font = 'bold 26px Verdana, Geneva, sans-serif'; // TODO: update for screen size
ctx.fillText('Soon...', 0, 30);



const textCoordinates = ctx.getImageData(0, 0, 200, 100);


class Particle {
  constructor(x, y) {
    this.x = x+4; //possibly x+100
    this.y = y;
    this.size = 3;
    this.chroma = 255;
    this.baseX = this.x;
    this.baseY = this.y;
    this.density = (Math.random() * 90) + 10;
  }

  draw() {
    let a = this.chroma;
    ctx.fillStyle = 'rgba(255, 255, 255,'+a+')';
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
    ctx.strokeStyle = 'rgba(255,255,255,' + a/255 + ')';
    ctx.moveTo(this.x, this.y);
    ctx.lineTo(this.x+20, this.y+20);
    ctx.stroke();
    ctx.closePath();
    ctx.fill();
  }

  update() {
    let dx = mouse.x - this.x;
    let dy = mouse.y - this.y;
    let distance = Math.sqrt((dx * dx) + (dy * dy));

    //let chroma = 255 * force * this.density;
    if (distance < mouse.radius) {
      let forceDirectionX = dx / distance;
      let forceDirectionY = dy / distance;
      let maxDistance = mouse.radius;
      let force = (maxDistance - distance) / maxDistance;
      let directionX = forceDirectionX * force * this.density;
      let directionY = forceDirectionY * force * this.density;
      this.x -= directionX;
      this.y -= directionY;
      this.chroma = 64 * force;
      this.size = 3 * force;
    } else {
      if (this.x !== this.baseX) {
        let dx = this.x - this.baseX;
        this.x -= dx / 5;
      }
      if (this.y !== this.baseY) {
        let dy = this.y - this.baseY;
        this.y -= dy / 5;
      }
      if (this.chroma !== 255) {
        this.chroma+=10;
      }

      if(this.size !== 3 && this.size < 3)
      {
        this.size++;
      }
    }
  }
}

function init() {
  particleArray = [];
  for (let y = 0, y2 = textCoordinates.height; y < y2; y++) {
    for (let x = 0, x2 = textCoordinates.width; x < x2; x++) {
      if (textCoordinates.data[(y * 4 * textCoordinates.width) + (x * 4) + 3] > 128) {
        let positionX = x + adjustX;
        let positionY = y + adjustY;
        particleArray.push(new Particle(positionX * 15, positionY * 15));
      }
    }
  }
}

init();

function animate() {
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  for (let i = 0; i < particleArray.length; i++) {
    particleArray[i].draw();
    particleArray[i].update();
  }
  connect();
  requestAnimationFrame(animate);
}

animate();

// Letter connect ( might be issues with threshold)
function connect() {
  let opacityValue = 1;
  for (let a = 0; a < particleArray.length; a++) {
    for (let b = a; b < particleArray.length; b++) {
      let dx = particleArray[a].x - particleArray[b].x;
      let dy = particleArray[a].y - particleArray[b].y;
      let distance = Math.sqrt(dx * dx + dy * dy);
      if (distance < 40) {
        opacityValue = 1 - (distance / 50);
        ctx.strokeStyle = 'rgba(255,255,255,' + opacityValue + ')';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(particleArray[a].x, particleArray[a].y);
        ctx.lineTo(particleArray[b].x, particleArray[b].y);

        // Calculate lesser RGB value
        ctx.strokeStyle = 'rgba(255,255,255,' + opacityValue + ')';
        ctx.moveTo(particleArray[a].x+20, particleArray[a].y+20);
        ctx.lineTo(particleArray[b].x+20, particleArray[b].y+20);
        ctx.stroke();
      }
    }
  }
}
